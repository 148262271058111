import * as React from 'react'
import _ from 'lodash'
import ShowCard from './ShowCard'
import { Link } from 'gatsby'

export default function SimilarShow({ shows, tops }) {
    const sortedTops = _.keys(
        _.fromPairs(_.sortBy(_.toPairs(tops), 1).reverse())
    )

    const sortedSimilars = _.sortBy(shows.nodes, o => sortedTops.indexOf(o.uid))

    const similars = _.take(sortedSimilars, 8)

    return (
        <div className="bg-gray-50">
            <div className="mx-auto px-6 py-10 max-w-7xl">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mb-6">
                    {similars.map(show => (
                        <ShowCard show={show} key={show.uid} />
                    ))}
                </div>

                <Link
                    className="font-medium text-primary"
                    to={`/emissions-series`}
                >
                    → Voir toutes les émissions et séries
                </Link>
            </div>
        </div>
    )
}
