import * as React from 'react'
import { PlayIcon } from '@heroicons/react/solid'

export default function ShowLinks({ data, doc }) {
    if (!data || !doc) return null

    const handleClick = iframeLink => {
        window.open(
            iframeLink,
            'newWin',
            'toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=0, width=1200, height=665'
        )
    }

    return (
        <ul className="mb-6">
            <li className="mb-2">
                <button
                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900 appearance-none focus:outline-none"
                    title={`${doc.name.text} en direct`}
                    onClick={() => handleClick(doc.live_link?.url)}
                >
                    <PlayIcon className="mr-2 w-6" />
                    <span>{`${doc.name.text} en direct`}</span>
                </button>
            </li>

            <li>
                <button
                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900 appearance-none focus:outline-none"
                    title={`${doc.name.text} en replay`}
                    onClick={() => handleClick(doc.replay_link?.url)}
                >
                    <PlayIcon className="mr-2 w-6" />
                    <span>{`${doc.name.text} en replay`}</span>
                </button>
            </li>
        </ul>
    )
}
