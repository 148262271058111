import * as React from 'react'
import { graphql, Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Similar from '../components/SimilarShow'
import AdSlot from '../components/AdSlot'
import TweetFeed from '../components/TweetFeed'
import ShowLinks from '../components/ShowLinks'

const Show = ({ data, pageContext }) => {
    const doc = data.prismicShow?.data
    const settings = data.prismicGeneralSettings?.data

    if (!data) return null

    return (
        <Layout>
            <Seo
                title={doc.seo_title?.text || doc.title?.text}
                description={doc.seo_description?.text}
                //ogImage={}
            />
            <div className="md:grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 pb-10 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="pb-4 border-b border-gray-300">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {prismicH.asText(doc.title?.richText)}
                        </h1>
                    </div>

                    {/* Live Channel Top Article Slot */}
                    <AdSlot
                        html={settings.live_channel_top_article_slot?.text}
                    />

                    <div className="mt-4 pb-10 prose prose-lg max-w-none text-gray-600">
                        <PrismicRichText
                            field={doc.content?.richText}
                            //serializeHyperlink={PrismicLink}
                        />

                        <div className="pt-2 border-t border-gray-200">
                            {/* Live Channel Top Article Slot */}
                            <AdSlot
                                html={
                                    settings.live_channel_top_article_slot?.text
                                }
                            />

                            <p>
                                Comment regarder {doc.name?.text} en direct sur
                                internet ? Sur cette page, vous trouverez un
                                bouton pour accéder au streaming live via{' '}
                                {doc.channel?.document?.data?.name?.text}. Si
                                vous rencontrez un problème, vous pouvez
                                consulter le{' '}
                                <Link to="/manuel">
                                    manuel d’utilisation de la TV en ligne
                                </Link>
                                . La plateforme ledirect.fr permet aussi
                                d’accéder au replay de {doc.name?.text} en
                                cliquant sur le bouton de rediffusion sur cette
                                page. Vous pouvez suivre les prochaines
                                diffusions de {doc.name?.text} en navigant sur
                                le{' '}
                                <Link
                                    to={`/programme-tv-${doc.channel?.document?.uid}`}
                                >
                                    programme de la chaîne{' '}
                                    {doc.channel?.document?.data?.name?.text}
                                </Link>
                                .
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pb-10">
                    <h2 className="mb-4 text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                        Les liens
                    </h2>

                    {/* Live Channel End Article Slot */}
                    <AdSlot html={settings.live_channel_end_article?.text} />

                    <ShowLinks data={data} doc={doc} />

                    {/* Live Channel Right After Links Slot */}
                    <AdSlot
                        html={
                            settings.live_channel_right_after_links_slot?.text
                        }
                    />

                    <TweetFeed handle={doc.twitter_handle?.text} />
                </div>
            </div>

            {/* Live Channel Suggestions Top Slot */}
            <div className="flex justify-center">
                <AdSlot
                    html={settings.live_channel_suggestions_top_slot?.text}
                />
            </div>

            <Similar shows={data.shows} tops={pageContext.tops} />
        </Layout>
    )
}

export default Show

export const query = graphql`
    query ShowQuery($uid: String!, $tops_uids: [String]) {
        prismicGeneralSettings {
            data {
                live_channel_top_article_slot {
                    text
                }
                live_channel_top_right_slot {
                    text
                }
                live_channel_right_after_links_slot {
                    text
                }
                live_channel_end_article {
                    text
                }
                live_channel_suggestions_top_slot {
                    text
                }
            }
        }
        prismicShow(uid: { eq: $uid }) {
            uid
            data {
                name {
                    text
                }
                title {
                    richText
                }
                channel {
                    document {
                        ... on PrismicChannel {
                            uid
                            data {
                                name {
                                    text
                                }
                            }
                        }
                    }
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                twitter_handle {
                    text
                }
                live_link {
                    url
                }
                replay_link {
                    url
                }
                content {
                    richText
                }
            }
        }
        shows: allPrismicShow(filter: { uid: { in: $tops_uids, ne: $uid } }) {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
